<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">{{ title }}</h1>
          <a
            class="btn-back right"
            href="javascript:;"
            @click="$router.history.go(-1)"
            >返回</a
          >
        </div>
        <div class="contest-con">
          <el-form :model="form" ref="form" :rules="rules" label-width="120px">
            <div class="info-box clearfix">
              <div class="left">
                <el-form-item label="作品标题：" prop="name">
                  <el-input
                    v-model="form.mname"
                    placeholder="请输入15字以内的作品标题"
                  ></el-input>
                </el-form-item>
                <el-form-item label="拍摄日期区间" prop="shoot_date">
                  <el-input
                    v-model="form.custom_fields.shoot_date"
                    placeholder="请输入拍摄日期区间 例：2019-2023"
                  ></el-input>
                </el-form-item>
                <el-form-item label="拍摄地址：" prop="shoot_address">
                  <el-input
                    v-model="form.shoot_address"
                    placeholder="请输入拍摄地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="作品总说明：" prop="desc">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.remark"
                    maxlength="1000"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
                <div class="agree-l-b">
                  <label>
                    <input
                      style="margin-right:5px;"
                      class="check"
                      type="checkbox"
                      :checked="isPromise"
                      @click="isPromise = !isPromise"
                    />
                    <span class="text">本人承诺递交的记录类作品未对原始影像进行违规处理</span>
                  </label>
                </div>
                <div class="info-item" style="display: block;">
                  <a
                    class="btn btn-bj"
                    href="javascript:void(0)"
                    @click="triggerUpload()"
                    >从本机选择图片</a
                  >
                  <el-upload
                    class="upload"
                    id="file_btn"
                    ref="upload"
                    drag
                    action=""
                    :show-file-list="false"
                    :auto-upload="true"
                    list-type="picture-card"
                    :accept="upAccept"
                    :multiple="true"
                    :http-request="(opt) => {handleHttpRequest(opt)}"
                    :on-change="(file, fileList) => {handleChange(file, fileList)}"
                  >
                  </el-upload>
                  <!-- <a class="btn btn-tk" href="javascript:;" @click="showGallery">从我的图库中选择图片</a> -->
                  <p class="tips-txt" v-show="msg">
                    {{msg}}
                  </p>
                  <p class="tips-txt">
                    （本赛事仅支持{{gourp_limit.typestr}}格式且不大于{{gourp_limit.size}}M的图片）
                  </p>
                </div>
              </div>
              <div class="right">
                <div class="hd2 clearfix">
                  <p class="left">已上传图片列表</p>
                  <p class="right">
                    <span class="imgCount">{{ uploadImgLen }}</span>/{{ gourp_limit.end }}
                  </p>
                </div>
                <div class="bd2 drag-list">
                  <draggable v-model="uploadList" animation="500" chosen-class="chosenDragItem" @update="dragUpdate" v-if="uploadList.length>0">
                    <div class="drag-item-wrap" v-for="(item, index) in uploadList" :key="index">
                      <div class="drag-item">
                        <el-image class="img" fit="cover" :src="item.url" :preview-src-list="[item.url]"></el-image>
                        <div class="desc">
                          <el-input class="ipt" type="textarea" v-model="item.describe" placeholder="请输入内容"></el-input>
                        </div>
                        <div class="btn-group">
                          <i class="el-icon-arrow-up" :style="{cursor: index === 0 ? 'not-allowed' : 'pointer'}" @click.stop="moveUpDragItem(index)"></i>
                          <i class="el-icon-close" @click.stop="removeDragItem(index)"></i>
                          <i class="el-icon-arrow-down" :style="{cursor: index === uploadList.length-1 ? 'not-allowed' : 'pointer'}" @click.stop="moveDownDragItem(index)"></i>
                        </div>
                      </div>
                      <div class="tips" style="color: red;" v-if="item.tips">*{{ item.tips }}</div>
                    </div>
                  </draggable>
                  <el-empty description="图片待上传" v-else></el-empty>
                </div>
              </div>
            </div>
            <div class="submit-box">
              <div class="submit-item">
                <label>
                  <input
                          class="check"
                          type="checkbox"
                          :checked="isAgreement"
                          @click="isAgreement = !isAgreement"
                  />
                  <span class="text">我同意将该图片同步到app发现中</span>
                </label>
              </div>
              <div class="agreement-tips">
                发布即表示您已同意我们的<a href="http://www.pai2345.com/web/Findworks/agreement" target="_blank">原创条款</a>
              </div>
              <div class="submit-item">
                <a class="btn"  @click="submitForm()" href="javascript:;">提交</a>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Gallery :transShowGallery="isShowGallery" @changeGallery="hideGallery" :end="gourp_limit.end"/>
  </div>
</template>

<script>
  import Gallery from "@/components/Gallery.vue";
  import draggable from 'vuedraggable';
  import EXIF from "exif-js";
  export default {
  components: {
      Gallery,
      draggable,
  },
  data() {
    return {
      uploadBg: require("@/static/res/images/img-bg.png"),
      dialogImageUrl: "",
      dialogVisible: false,
      id: this.$route.params.id,
      title: '多图投稿',
      upAccept: "image/png,image/jpeg",
      define: [],
      custom_fields: [],
      group: this.$route.query.group,
      // 是否同意条款
      isAgreement: false,
      isPromise: true,
      form: {
        mname: "",
        shoot_address:'',
        remark: "",
        custom_fields: [],
      },
      rules: {},
      // 限制图片数量
      uploadImgLen: 0,
      fileList: [],
      uploadList: [],
      isUploaded: true,
      gourp_limit:{
          typestr:'',
          imgtype:[],
          size:'',
          end:2,
          start:1,
          is_open_psd:0,
      },
      msg:'',
      isShowGallery: false,
      isSubmit: false,
    };
  },
  methods: {
    //列表更新
    dragUpdate(){
      
    },
    //上移
    moveUpDragItem(index){
      if(index===0){
        return false
      }
      this.uploadList.splice(index - 1, 1, ...this.uploadList.splice(index, 1, this.uploadList[index - 1]));
    },
    //下移
    moveDownDragItem(index){
      if(index===this.uploadList.length-1){
        return false
      }
      this.uploadList.splice(index, 1, ...this.uploadList.splice(index + 1, 1, this.uploadList[index]));
    },
    //删除
    removeDragItem(index){
      this.uploadList.splice(index, 1);
    },
    triggerUpload() {
      document.getElementById("file_btn").querySelector(".el-upload__input").click();
    },
    hideView() {
      this.isUploaded = false;
    },
    showGallery() {
        this.isShowGallery = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    hideGallery(arr) {
        this.isShowGallery = false;
        let len = arr.length;
        let count = this.uploadImgLen;
        if(len > 0){
          let end = this.gourp_limit.end;
          if (end > 0) {
            if (count + len > end) {
              this.$message("您上传的图片超过上限" + end + "张，请调整后重试！");
              return false;
            }
          }
          for(let i = 0; i < len; i++) {
            let element = {
              url: arr[i],
              describe: '',
            };
            this.uploadList.push(element);
            this.uploadImgLen++;
            this.$forceUpdate();
          }
        }
    },
    /**
     * 选择文件后生成MD5并且获取oss直传密钥
     * @param file
     * @param filelist
     */
    handleChange(file, fileList){
      if (file.status == 'ready') {
        // 计算图片尺寸
        let image = new Image();
        image.src = file.url;
        image.setAttribute('crossOrigin', 'anonymous'); // 跨域
        image.onload = async () => {
          // console.log('width=' + image.width, 'height=' + image.height);
          file.width = image.width;
          file.height = image.height;
          if(image.width < this.gourp_limit.min_width){
            file.width = image.width;
          }
        };
        // 判断exif信息
        EXIF.getData(file.raw, () => {
          var exifData = EXIF.getAllTags(this);
          // console.log('exif===========', exifData);
        });
        this.fileList = fileList;
      }
    },
    /**
     * 上传到OSS
     * @param opt
     */
    handleHttpRequest(opt) {
      this.tools.getFileMd5(opt.file, (fileMd5) => {
        //上传到OSS
        opt.file.md5 = fileMd5;
        this.tools.uploadaly(opt.file).then(res=>{
            if (res) {
              let element = {
                uid: opt.file.uid,
                url: res,
                describe: '',
              };
              this.uploadList.push(element);
              this.uploadImgLen++;
            } else {
              console.log(res);
            }
        })
      });
    },
	  submitForm() {
      let that = this;
      if (this.isSubmit == false) {
        if (this.form.mname == "") {
          this.$message.error("作品标题必须填写");
          return false;
        }
        let start = 2;
        if(this.start > 0){
            start = this.gourp_limit.start;
        }
        if(this.uploadList.length < start){
            this.$message("您上传的图片超过至少" + start + "张，请调整后重试！");
            return false;
        }
        if(this.gourp_limit.end > 0){
            if(this.uploadList.length > this.gourp_limit.end){
                this.$message("您上传的图片超过上限" + this.gourp_limit.end + "张，请调整后重试！");
                return false;
            }
        }
        // 图片校验
        let uploadError = false;
        for(let i in this.uploadList){
          for(let j in this.fileList){
            if(this.uploadList[i].uid == this.fileList[j].uid){
              let fileinfo = this.fileList[j];
              // console.log('fileinfo========', fileinfo);
              let upAccept = this.upAccept.toLowerCase();
              let exifData = fileinfo.raw.exifdata;
              // console.log('exifData========', exifData);
              // console.log('exifData DateTime========', exifData.DateTimeOriginal);
              if(upAccept.indexOf(fileinfo.raw.type.toLowerCase()) < 0){
                // 上传格式判断
                uploadError = true;
                this.uploadList[i].tips = '上传文件只能是图片格式[' + this.gourp_limit.typestr + ']!';
              }else if(fileinfo.raw.size / 1024 / 1024 < 3){
                // 上传文件大小判断
                uploadError = true;
                this.uploadList[i].tips = '上传文件大小不能小于3MB!';
              }else if(fileinfo.raw.size / 1024 / 1024 > this.gourp_limit.size){
                // 上传文件大小判断
                uploadError = true;
                this.uploadList[i].tips = '上传文件大小不能超过 '+this.gourp_limit.size+'MB!';
              }else if(this.gourp_limit.min_width > 0 && fileinfo.width < this.gourp_limit.min_width && fileinfo.height < this.gourp_limit.min_width){
                // 判断图片尺寸
                uploadError = true;
                this.uploadList[i].tips = '上传图片长边不能低于'+this.gourp_limit.min_width+'像素';
              }else if(typeof exifData.DateTimeOriginal === 'undefined'){
                // 判断EXIF信息
                // uploadError = true;
                // this.uploadList[i].tips = '图片缺少EXIF完整信息!';
              }else if(this.uploadList[i].describe == ''){
                // 判断图片说明是否为空
                uploadError = true;
                this.uploadList[i].tips = '图片说明不能为空!';
              }else{
                this.uploadList[i].tips = '';
              }
            }
          }
        }
        if(uploadError){
          this.$forceUpdate();
          this.$message.error("上传图片格式错误请重新上传！");
          return false;
        }
        if (this.form.custom_fields.shoot_date == "") {
          this.$message.error("拍摄日期必须填写");
          return false;
        }
        if (this.form.shoot_address == "") {
          this.$message.error("拍摄地址必须填写");
          return false;
        }
        if (this.form.remark == "") {
          this.$message.error("作品说明必须填写");
          return false;
        }
        if (!this.isPromise) {
          this.$message.error("请确定承诺");
          return false;
        }
        let custom_fields = {
          shoot_date: this.form.custom_fields.shoot_date,
        };
        this.isSubmit = true;
        // join_multi   test_single
        this.axios.post("/match/join_multi",
          {
              work_id: this.$route.query.work_id,
              match_id: that.id,
              mname: this.form.mname,
              remark: this.form.remark,
              imgs: this.uploadList,
              group: this.group,
              custom_fields:custom_fields,
              shoot_address:this.form.shoot_address,
              is_finding:this.isAgreement,
              works_type:2,
              source:2
          }
        )
        .then(res => {
            if(res.data.code == 200){
                this.$confirm('投稿成功!', '确认信息', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '再投一稿',
                    cancelButtonText: '返回我的作品'
                })
                    .then(() => {
                        var initUrl = window.location.href;
                        initUrl = initUrl.replace("work_id","init");
                        window.location.href = initUrl;
                        window.location.reload();
                    })
                    .catch(action => {
                        console.log(action);
                        this.$router.push({
                            path: '/detail/'+ that.id+'/myworks'
                        });
                    });
            }else {
                this.$message(res.data.msg)
            }
            this.isSubmit = false;
        },err=>{
          this.isSubmit = false;
          console.log(err);
        });
      }
	  },
    init(){
        this.tools.getMatchDetail({id:this.id,group:this.group}).then(res=>{
          this.title = res.gourp_limit['groupname']+'投稿';
          this.define = res.custom_fields;
          this.msg = res.match_limit.match_introduce;
          var params = res.gourp_limit;
          if(res.match_limit.is_app_show == 1){
              this.isAgreement = true;
          }
          if(params){
              this.gourp_limit = Object.assign(this.gourp_limit,params);
              this.gourp_limit.size = params.file_limit;
              this.gourp_limit.imgtype = params.file_limit_type;
              this.gourp_limit.typestr = this.gourp_limit.imgtype.toString();
          }
          // console.log('gourp_limit=======', this.gourp_limit);
      })
    }
  },
  mounted() {
      if(this.$route.query.work_id){
        this.tools.getWorksDetail({work_id:this.$route.query.work_id}).then(res=>{
            this.form = res.form;
            this.uploadImgLen = res.imgs.length;
            this.id = this.form.m_id;
            this.group = this.form.group;
            this.uploadList = res.imgs;
            this.init();
        })
      }else {
        this.fileList = [];
        this.init();
      }
  }
};
</script>

<style>
/* .contest-submit .info-box .left .info-item.textarea-box {
  align-items: top;
}
.contest-submit .info-box .left .textarea-box .label {
  vertical-align: top;
  display: inline-block;
  height: 150px;
} */
/* .contest-submit .info-box .left .info-item .label {
  min-width: 90px;
} */
.contest-submit .info-box .left .el-textarea__inner {
  width: 100%;
}
.el-form-item__label {
  font-size: 16px;
}
.contest-submit .info-box .left .is-error textarea {
  border-color: #f56c6c;
}
.error {
  line-height: 24px;
  height: 24px;
  color: #f56c6c;
}
.el-input__inner:focus,
.el-input__inner:hover {
  border-color: #ddd;
}
.el-textarea .el-input__count {
  line-height: 16px;
  right: 20px;
}
.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
  border: 1px solid #eee;
  border-radius: 0;
}
.contest-submit .info-box .left .info-item{
  text-align: center;
}
.contest-submit .left .el-form-item .el-checkbox__label,
.contest-submit .left .el-form-item .el-radio__label
{
  font-size: 16px!important;
}
.drag-list{
  max-height: 600px;
  overflow: auto;
}
.drag-list .drag-item-wrap{
  margin-bottom: 15px;
}
.drag-list .drag-item-wrap .tips{
  cursor: move;
  margin-top: 5px;
}
.drag-list .drag-item{
  display: flex;
  cursor: move;
  position: relative;
}
.drag-list .drag-item .img{
  width:120px;
  height:80px;
  cursor: move;
}
.drag-list .drag-item .img img{
  width:100%;
  height:100%;
  border-radius:5px;
  cursor: move;
}
.drag-list .drag-item .desc{
  flex: 1;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  height: 80px;
  overflow: auto;
  text-align: justify;
  margin-left: 10px;
}
.drag-list .drag-item .desc .ipt{
  width:100%;
  height: 100%;
}
.drag-list .drag-item .desc .ipt .el-textarea__inner{
  width:100%;
  height: 100%;
  resize: none;
}
.drag-list .drag-item .desc .ipt .el-textarea__inner:focus{
  border-color: #ef7f48;
}
.drag-list .drag-item .desc::-webkit-scrollbar{
  width: 6px;
}
.drag-list .drag-item .desc::-webkit-scrollbar-thumb{
  background: #ccc;
}
.drag-list .drag-item .desc::-webkit-scrollbar-track-piece {
  background: #f6f6f6;
}
.drag-list .drag-item:hover{
}
.drag-list .chosenDragItem{
  box-sizing: border-box;
  overflow: hidden;
}
.drag-list .drag-item .btn-group{
  height: 80px;
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
  display: none;
}
.drag-list .drag-item .btn-group i{
  width:24px;
  height:24px;
  display: flex;
  justify-content:center;
  align-items: center;
  cursor: pointer;
  color: #333;
}
.drag-list .drag-item .btn-group i:hover{
  color: #ef7f48;
  background:#d2d2d2;
}
.drag-list .drag-item:hover .btn-group{
  display: flex;
}
.contest-submit .left .agree-l-b{
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 15px 0;
}
.contest-submit .left .agree-l-b .check{
  vertical-align: middle;
}
</style>
